a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

h1 {
  font-size: 1.6rem;
  margin-top: 1rem;
}
#sumary-section div {
  margin-left: 15px;
}
#summary-section div p {
  min-height: 40px;
}
#sumary-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
#sumary-section .available {
  display: flex;
  justify-content: flex-end;
}
#cv {
  display: flex;
}
.dwlCV {
  margin-top: -5px;
  width: 35px;
}
.dwlCV img {
  width: 100%;
}
#expertises-section h5 {
  color: $secondary;
}

#expertises-section li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#expertises-section ul {
  padding-left: 20px;
}

#graph-view p {
  text-align: center;
}
.svg-container {
  /*display: inline-block;
  position: relative;*/
  width: 100%;
  /*vertical-align: middle;
  overflow: hidden;*/
}
.svg-content {
  /*display: inline-block;*/

  width: 100% !important;
  height: 100% !important;
}

#t-xps {
  display: none;
}
#xp-section {
  margin-top: 20px;
}
.xp-details {
  border: 1px solid grey;
  border-radius: 10px;
  max-width: 400px;
  margin: 10px 0 10px;
}
#XP,
#politiqueConfidentialite {
  padding-top: 60px;
  min-height: 78vh;
}

#CV {
  padding-top: 60px;
}

.breadcrumb {
  background-color: $myWhite !important;
}
#xp-title {
  max-height: 100px;
  text-align: center;
}
#xp-title img {
  height: 100%;
}

#xp-sumary h1 {
  font-size: 1.5rem;
}
#xp-sumary h3 {
  font-size: 1.2rem;
}

#xp-content {
  padding-top: 10px;
}
#xp-content h2,
#politiqueConfidentialite h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  position: relative;
}
#xp-content h2::after,
#politiqueConfidentialite h2:after {
  content: "";
  background-image: linear-gradient(to right, $secondary, $myWhite);
  height: 3px;
  display: block;
  width: 40%;
}

#xp-content h3,
#politiqueConfidentialite h3 {
  //margin-left: 1rem;
  font-size: 1.2rem;
  position: relative;
  margin-top: 0.7rem;
}
#xp-content h3::after,
#politiqueConfidentialite h3:after {
  content: "";
  background-image: linear-gradient(to right, $primary, $myWhite);
  height: 3px;
  display: block;
  width: 40%;
}

#xp-content h4 {
  font-size: 1.2rem;
  margin-top: 10px;
  //  margin-bottom: 0px;
}

#xp-content ul {
  list-style: none;
  margin-top: -0.7rem;
  margin-bottom: 1rem;
}
#xp-content li {
  margin-left: 2em;
}
#xp-content li ul {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
#xp-content li::before {
  content: "\29C1";
  //content: "\25CB";
  color: $primary;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

#xp-content p {
  text-align: justify;
}
#xp-content .xp-tag {
  font-size: 1em;
  margin-top: 5px;
}

#XP .navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#XP .navigation a {
  margin: 10px 20px;
}

/* SLIDE Graph - Details */

.slider {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
  margin-bottom: 10px;
}

.center-image
{
    margin: 0 auto;
    display: block;
}

@media (min-width: 500px) {
  h1 {
    font-size: 1.8rem;
  }
  #xp-content h2::after,
  #xp-content h3::after {
    width: 30%;
  }
}

@media (min-width: 720px) {
  h1 {
    font-size: 2rem;
  }
  #xp-content h2::after,
  #xp-content h3::after {
    width: 20%;
  }
}

@media (min-width: 992px) {
  #xp-content h2::after,
  #xp-content h3::after {
    width: 15%;
  }
}
