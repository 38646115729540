$gbcreation-1: rgba(38,107,178,1);
$gbcreation-2: rgba(1,147,163,1);

$brand-primary: rgba(232, 149, 39, 1);
//$brand-secondary: rgba(152, 203, 227, 1);
$brand-secondary: $gbcreation-1;

$primary: $brand-primary;
$secondary: $brand-secondary;
$tertiary: $gbcreation-2;

$myWhite: #f8f9fa;

$font-size-base: 1rem !default;
//$font-family: 'Source Sans Pro';
$font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif, sans-serif;
$font-family-monospace: 'Source Code Pro', monospace;



/*
$special-highlight: #ffb500;
$footer-secondary-color: #bb00796b;*/