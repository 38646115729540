/* reset */
* {
  box-sizing: border-box;
}
html,
body,
ul {
  padding: 0;
  margin: 0;
}
.debug {
  border: solid red 2px;
}

body {
  font-family: $font-family !important;
  background-color: $myWhite;
}

h1,
h2,
h3,
h4,
h5 {
  /*color: $secondary;*/
}

h2 {
  font-size: 1.6rem;
}

.navbar a {
  color: $tertiary !important;
}
.navbar-dark .nav-item {
  background: black;
}
.custom-toggler {
  border-color: $primary !important;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(232, 149, 39, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-brand img {
  height: 50px;
}
.nav-light {
  background: $myWhite;
}
.nav-light a {
  color: black;
}

a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
#politiqueConfidentialite a.anchor {
  top: -100px;
}

.gradient {
  background: -webkit-linear-gradient($secondary, $tertiary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#cover-homepage {
  background-image: url("/assets/images/banner4.jpg");
  //background-position:50% 50%; //pour mettre un bout image
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  min-height: 50vh; // 100% de la hauteur du viewport
  color: $secondary;
}

#cover-homepage h1 {
  font-size: 1.5rem;
}
#cover-homepage header {
  padding: 5em 1em 2em;
}
#cover-homepage button {
  text-decoration: none;
}
#cover-homepage .btn-outline-primary:hover a {
  color: $myWhite !important;
  text-decoration: none;
}

#sectionServices {
  margin-top: -50px;
  margin-bottom: 50px;
}
.btn-primary {
  color: $myWhite;
  min-width: 100px;
}
.circle-icon {
  background: $primary;
  padding: 15px;
  border-radius: 50%;
  color: $myWhite;
  text-align: center;
  font-size: 2rem !important;
}
.btn-circle {
  background: transparent !important;
  border: 0 none !important;
  /*border-color: transparent !important;*/
}

#sectionPresentation p {
  text-align: justify;
  text-justify: inter-word;
}

.profil {
  width: 100%;
}

#sectionContact form {
  width: 80%;
  margin: 50px auto;
}
#sectionContact form .btn-primary {
  margin: 0 auto;
  display: block;
}

#sectionClients {
  background: rgb(24, 19, 20);
  color: whitesmoke;
  padding-top: 10px;
  margin-right: -15px;
  margin-left: -15px;
  padding-bottom: 50px;
}

.clientDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

//Centrer verticalement :
//container : line-height + item vertical-align
.xp {
  width: 200px;
  height: 150px;
  text-align: center;
  line-height: 150px;
}
.xp img {
  vertical-align: middle;
  /*background: linear-gradient(
    rgba(0, 0, 0, 0.45), 
    rgba(0, 0, 0, 0.45)
    )*/
  filter: brightness(20%);
  -webkit-filter: brightness(20%);
  -moz-filter: brightness(20%);
  -o-filter: brightness(20%);
  -ms-filter: brightness(20%);
}
.clientDisplay img:hover {
  filter: brightness(70%);
  -webkit-filter: brightness(70%);
  -moz-filter: brightness(70%);
  -o-filter: brightness(70%);
  -ms-filter: brightness(70%);
}

.footer-home {
  font: 10pt Verdana;
  letter-spacing: 1px;
  background-color: black;
  color: $primary;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.footer-home h3 {
  font-size: 1.5em;
}
.footer-home i {
  width: 25px;
  text-align: center;
  color: whitesmoke;
}
.footer-home i.fa-copyright {
  color: $primary !important;
}
#footer-contact {
  padding: 20px 0 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 150px;
}
#footer-copyright {
  text-align: center;
}
.spacer {
  margin-top: 20px;
  /* trait léger ??????*/
}

.footer-home .social {
  text-align: center;
}
.footer-home .social li {
  list-style: none;
  display: inline-block;
}
.footer-home .social img {
  max-width: 25px;
}

#cookie-notice {
  padding: 7px 15px;
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #222;
  color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

#cookie-notice a {
  cursor: pointer;
  margin-left: 10px;
}

@media (min-width: 500px) {
  #cover-homepage header {
    text-align: left;
  }
  #footer-contact {
    justify-content: space-around;
  }
  h2 {
    font-size: 1.8rem;
  }
  .circle-icon {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 720px) {
  #cover-homepage {
    background-position: center;
    min-height: 60vh;
  }
  #cover-homepage header {
    padding-top: 7em;
    padding-left: 5em;
  }
  h2 {
    font-size: 2rem;
  }
  .circle-icon {
    font-size: 3rem !important;
  }
}

@media (min-width: 992px) {
  #cover-homepage {
    min-height: 75vh;
  }
  #cover-homepage header {
    padding-top: 6em;
    padding-left: 10em;
  }
  #cover-homepage header button {
    margin-top: 2em !important;
  }
}
