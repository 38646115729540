@charset "utf-8";
//url('https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400,600'),        // TODO font !!!
//url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,900'),

// Import partials from `sass_dir` (defaults to `assets/_sass`)
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "variables";                // palette pour gestion couleurs, font, ..
@import "bootstrap-custom-4.5.3";   // boostrap custom
@import "home";
@import "cv";
@import "xp";
@import "xps-tools";

